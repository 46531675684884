import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  noButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: theme.palette.grey[500],
  },
  yesButton: {
    margin: theme.spacing(0, 3, 2, 0),
  },
}));

export default function DeleteDialogComponent(props) {
  const classes = useStyles();
  const { isShowing, dialogTitle, dialogText, onClose, onDelete } = props;

  return (
    <Dialog
      open={isShowing}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.noButton} onClick={onClose} color="primary">
          No
        </Button>
        <Button
          className={classes.yesButton}
          onClick={onDelete}
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialogComponent.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
